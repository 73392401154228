@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Ubuntu&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600;700&display=swap');

.App {
  /* text-align: center; */
}

.connectWallet {
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
}

.cwTop {
  width: 100%;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.NumBtn {
  width: 204.75px;
  height: 51.3675px;
  margin-right: 2.5%;
  cursor: pointer;
}

.cwBottom {
  width: 100%;
  height: 90vh;
  display: flex;
}

.cwBottomLeft {
  width: 50%;
  height: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.cwBottomLeftHead {
  font-family: 'Red Hat Display', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 37.5px;
  line-height: 66px;
}

.cwBottomRight {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cwBottomLeftInner {
  width: 80%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cwBottomLeftBody {
  font-family: 'Quicksand', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 31px;
  text-align: justify;
  margin-top: 10px;
  margin-bottom: 30px;
}

.cwbtn {
  width: 134.25px;
  height: 46.5px;
  color: white;
  background: #2B6877;
  border-radius: 9px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 32px;
  cursor: pointer;
  margin-right: 20px;
}

.cwbtn:hover {
  background: #204e5a;
}

.cwbtn:active {
  background: #204e5a;
  transform: translateY(2px);
}

.cwBottomLeftBtn {
  display: flex;
  align-items: center;
}

.cwLink {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  color: #2B6877;
  border-bottom: 1px solid #2B6877;
  padding-bottom: 5px;
  cursor: pointer;
}